import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import { useStaticQuery, graphql } from "gatsby";
import { isSafari, isIOS, isMacOs } from "react-device-detect";
import $ from "jquery";
import logo from "../../images/logo.svg"
import logoNew from "../../images/redbrik-new-logo.svg"
import logo02 from "../../images/tab-logo.png"
import logo03 from "../../images/desktop.png"
import { Container, Row, Col } from "react-bootstrap"
import Helmet from 'react-helmet'
import GenerateLink from "../common/site/generate-link"
import SearchForm from "./Search"
import "./Header.scss"
import parse from 'html-react-parser';
import { isTablet, isMobile, isMobileOnly } from 'react-device-detect';

const Header = (props) => {
  const [isClient, setClient] = useState(false);
  useEffect(()=>{
    if (typeof window !== "undefined") {
      const metaTag = document.createElement("meta");
      metaTag.name = "facebook-domain-verification";
      metaTag.content = "i58k054jw4oubxi119hocnsaeefeme";
      document.head.appendChild(metaTag);
    }
  },[]);
  useEffect(() => {
    //console.log('aaaaaaaaaaaaaa',isSafari,isIOS)
    setClient(true)
    if (isSafari === true || isIOS === true || isMacOs === true) {
      $('body').addClass('iosdevicesonly');
    }
    if (sessionStorage.getItem("StickyHeadet") != "1") {
      setSticky(false)
      //$('body').addClass('withStickyBand');

    }

  });


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusBurgers(sort: "Sort:asc") {
        Parent_Label
        Parent_Link {
          id
        }
        Sort
        Sub_Menus {
          Label
          Link {
            id
          }
        }
      }


      siteConfig {
        Site_Contact
        Burger_Menu_Quick_Links {
          Label
          Link {
            id
          }
        }
        Top_Navigation_Links {
          Label
          Link {
            id
          }
          Add_Sub_Menu {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }

    }
  }
`);



  const menus = data.glstrapi.menusBurgers;
  const quick_links = data.glstrapi.siteConfig.Burger_Menu_Quick_Links;
  const top_links = data.glstrapi.siteConfig.Top_Navigation_Links;
  const site_contact = data.glstrapi.siteConfig.Site_Contact;



  const [state, setState] = useState(menus);
  const [statetop, setStateTop] = useState(top_links);
  const [sticky, setSticky] = useState(false);

  const [issubmenutop, setIssubmenuTop] = useState([]);
  const [issubmenu, setIssubmenu] = useState([]);
  const [ham, setHam] = useState(false);
  const [hamSearch, setHamSearch] = useState(false);
  const [hamtop, setHamTop] = useState(false);

  const main_page = typeof window !== 'undefined' ? window.location : ''
  var page = ''
  if (main_page.pathname) {
    page = main_page.pathname.split("/")[1]
  }

  const closeHam = () => {
    setHam(!ham);
    setHamTop(!hamtop);
    if(!ham) {
        $(".header-wrapper .header-search, .header-wrapper .login-wrap").addClass("hidden")

    }else{
      $(".header-wrapper .header-search, .header-wrapper .login-wrap").removeClass("hidden")
    }
     console.log('sdkjsd' + ham)
  };

  const closeHamSearch = () => {
    setHamSearch(!hamSearch);
  };


  const handlerClick = (item, index) => {
    if (item.Sub_Menus.length > 0) {
      let a = state.map((item, i) => {
        if (i === index) {
          if (item.isopen) {
            setIssubmenu([]);
            return { ...item, isopen: false };
          } else {
            setIssubmenu(item.Sub_Menus);
            return { ...item, isopen: true };
          }
        } else {
          return { ...item, isopen: false };
        }
      });
      setState(a);
    } else {
      setIssubmenu([]);
      let a = state.map((item, i) => {
        return { ...item, isopen: false };
      });
      setState(a);
    }
  };

  const handlerClickTop = (item, index) => {
    if (item.Add_Sub_Menu.length > 0) {
      let a = statetop.map((item, i) => {
        if (i === index) {
          if (item.isopen) {
            setIssubmenuTop([]);
            return { ...item, isopen: false };
          } else {
            setIssubmenuTop(item.Add_Sub_Menu);
            return { ...item, isopen: true };
          }
        } else {
          return { ...item, isopen: false };
        }
      });
      setStateTop(a);
    } else {
      setIssubmenuTop([]);
      let a = statetop.map((item, i) => {
        return { ...item, isopen: false };
      });
      setStateTop(a);
    }
  };

  const closeStickyHeader = () =>{
    sessionStorage.setItem("StickyHeadet", 1)
    setSticky(false) 
    $('body').removeClass('withStickyBand');
  }

  return (
    <>
      { /*sticky &&
        <div className="headerSticky">
          <p><strong>Looking to Sell or Let your home?</strong> Book your <Link to={`/property-valuation/`}>free property valuation</Link> today <i className="icon-white-arrow" ></i></p>
          <p className="mobile-views"><strong>Looking to Sell or Let?</strong> Book a <Link to={`/property-valuation/`}>valuation</Link> today <i className="icon-white-arrow" ></i></p>
          <i className="icon-x-mark icon-x-mark-sticky" onClick={closeStickyHeader}></i></div>
      */
      }

      <header className={`header ${sticky ? 'with-band' : 'no-band'}`} id="pageheader">

        <Helmet>

        {main_page?.pathname?.includes('/property-valuation/') &&
<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Should I book a home visit valuation or an instant valuation?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you are looking to put your property on the market in the near future, we recommend booking a home visit valuation. If you are curious about the price of your property but aren’t currently looking to sell, we would suggest starting with an instant valuation."
    }
  },{
    "@type": "Question",
    "name": "How much will it cost to sell my property?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our fees are tailored to each property based on the price and what marketing is required. Upon valuation, the Property Consultant will discuss what recommendations they have for your property and will give you multiple fee options on the back of that. You will have the option to receive a discount on the overall fee, should you wish to make an upfront payment."
    }
  },{
    "@type": "Question",
    "name": "What information will I receive on the valuation?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Property Consultant will talk you through the valuation and give you advice based on your moving circumstances. They will help you understand what marketing tools are available to you, give you information on our Redbrik SecureMove™ service and explain how we will get your property sold."
    }
  },{
    "@type": "Question",
    "name": "How will I know if the valuation is accurate?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Property Consultant will value your property based on area, square footage and features, and offer information on recently sold comparable properties, so you can see that the pricing is realistic."
    }
  }]
}`}
</script>
}
{main_page?.pathname?.includes('/sell/') &&
<script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why do I need an estate agent?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Market knowledge and pricing expertise: Estate agents have in-depth knowledge of the local property market. We can provide you with information on current market conditions, property values, and trends that can help you make informed decisions. Agents can help you set the right price for your property if you're selling or negotiate a fair price if you're buying. We have access to recent sales data and can perform comparative market analysis to determine the value of a property.

Marketing and exposure: When selling a property, estate agents can market it effectively through multiple channels, including online listings, open houses, and to a database of registered applicants. This exposure can help attract potential Buyers and increase your chances of selling at a desirable price.

Legal and contractual guidance: Property transactions involve complex legal documents and contracts. Estate agents can help you understand these documents and ensure all the necessary paperwork is completed accurately and on time."
    }
  },{
    "@type": "Question",
    "name": "What is included in the fee for selling my property?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The fee covers property marketing, including professional photography, floor plans, and a video tour. You will have a designated Sales Manager who will look after you and your property from start to finish, negotiating on offers and supporting you through the conveyancing process."
    }
  },{
    "@type": "Question",
    "name": "I have received an offer, what happens next?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Your allocated Sales Manager will call you to discuss the offer, the buying position of the offeree and any conditions that come with their offer. It is their responsibility to understand what offer you want to accept and negotiate between yourself and the potential Buyer. Once you have come to an agreement on an acceptable offer, your agent will do all of the appropriate due diligence to be able to mark the property as ‘sold subject to contract’."
    }
  },{
    "@type": "Question",
    "name": "Why should I choose a traditional estate agent over an online agent?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The traditional agent has a great understanding of properties in the area and can accurately advise on the listing price. At Redbrik, we have a pool of local searching Buyers who will be matched to the property to encourage viewings. An online agent is usually from out of area, so won’t have the local knowledge required to understand the price point of the home and find suitable Buyers."
    }
  }]
}`}
</script>
}
{main_page?.pathname?.includes('/new-homes/') &&
  <script type="application/ld+json">
    {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What does buying off-plan mean?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Buying off-plan refers to purchasing a property before construction is complete and, in some cases, before the building work has even begun.

Essentially, a buyer will agree to purchase a property based on the plans and specifications provided by the developer, often without physically seeing the finished product. Occasionally developers may offer discounts or incentives to early buyers and it is a great way to ensure you secure your desired plot."
    }
  },{
    "@type": "Question",
    "name": "What is a new home warranty?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A new home warranty, also known as a structural warranty or guarantee, is a type of insurance policy that covers certain aspects of a newly constructed property. They are designed to protect the homeowner against defects in materials or workmanship that may arise after the home is completed. New home warranties usually have a duration of several years, with different coverage periods for different components of the home."
    }
  },{
    "@type": "Question",
    "name": "What are the benefits of buying a new build?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are lots of benefits to buying a brand-new home, including structural guarantees, modern and multifunctional layouts, and opportunities to customise to your own taste, often with a choice of kitchens, flooring and tiling. And thanks to a range of energy-efficient and eco-friendly features, new homes can be up to four times more efficient than an older property, with many achieving the highest EPC ratings of A or B, resulting in lower bills. New build properties also have the advantage of being chain-free, so you don’t have to wait for the Seller to find somewhere to move to."
    }
  },{
    "@type": "Question",
    "name": "I am looking to buy some land, can you help?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Absolutely. We are often approached by clients to help them sell land, so are aware of available opportunities. Let us know your requirements and we will keep you informed - whether you have just started your search or are ready to put a spade in the ground, we are happy to assist."
    }
  },{
    "@type": "Question",
    "name": "How can you help market my development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Experts in what we do, we offer a unique, personal service for every development that we market. As a specialist Land & New Homes department, we work closely with house builders and developers, sharing our knowledge, expertise, and buyer insight throughout the process. We continually analyse the market and buyers’ evolving wants and needs, to offer the most accurate and up-to-date advice to our clients. With an experienced and dynamic sales team, and exceptional marketing talent, we offer a complete service from site acquisition to practical completion."
    }
  }]
}`}
</script>
}
{main_page?.pathname?.includes('/securemove/') &&
  <script type="application/ld+json">
 {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How does SecureMove™ give me more certainty in my property sale?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Buyer pays a non-refundable reservation fee and enters into a contractual reservation agreement, whereby time frames for moving are agreed on the date of offer acceptance. The reservation fee covers the costs of the searches and Buyer Information Pack, provided to the Buyer prior to offering. Sellers have a financially committed Buyer with all the required information upfront, decreasing the likelihood of a sale fall-through."
    }
  },{
    "@type": "Question",
    "name": "How long on average does it take from on the market to completion with Redbrik SecureMove™?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "90 days on average - this depends on the Sellers and Buyers timescales, requirements from the solicitors during the conveyancing process and other properties within the chain's circumstances."
    }
  },{
    "@type": "Question",
    "name": "Do I have to sell/buy with Redbrik SecureMove™?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All properties sold by Redbrik use the SecureMove™ process. Buyers cannot opt out of purchasing a property which is sold using Redbrik SecureMove™ to ensure fairness to all Sellers and Buyers."
    }
  }]
}`}
</script>
}
        
        </Helmet>

        <Container>
          <Row>
            <Col sm={12}>
              <div className="header-wrapper">
                <div className="logo">
                  <Link to="/">

                    <img src={logoNew} alt={`${sitename} Estate Agents logo`} />

                  </Link>
                </div>
                <nav className="nav-bar">
                  <ul className="nav-menu">
                    {/* <li className="dropdown-top position-relative find-a-property">
                    <div className="main-title">Find a property</div>
                    <div className="sub-menu-top">
                      <div className="sub-menu find-a-property-popup">
                        <div className="title">Find your perfect home</div>
                        <SearchForm page={page} />
                      </div>
                    </div>
                  </li> */}
                    {top_links &&
                      top_links.map((item, i) => (
                        <>
                          {item.Add_Sub_Menu.length !== 0 ?
                            <li className="dropdown-top position-relative">
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                              {item.Add_Sub_Menu &&
                                <div className="sub-menu-top" >
                                  <div className="sub-menu" >
                                    <div className="menu-lists" >
                                      {item.Add_Sub_Menu.map((subLink, k) => {
                                        return (
                                          <GenerateLink className={k === 0 ? "none" : "border-after"} link={subLink.Submenu_Link}>{parse(subLink.Submenu_Label)}</GenerateLink>
                                        )
                                      }
                                      )}
                                    </div>
                                   {/* <div className="valuation-block">
                                      <div className="h3">Selling your home <span>doesn’t need to be difficult.</span></div>
                                    </div>
                                    */}
                                  </div>

                                </div>
                              }
                            </li> :
                            <li>
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                            </li>
                          }
                        </>
                      )
                      )}
                  </ul>
                </nav>
                <div className="menu-icons">
                 {/* <a className="header-search" onClick={(e) => { e.preventDefault(); closeHamSearch(); }}>
                    <i className="icon-search-header"></i>
                  </a>
                  */}
                  {/*<div className="signup-wrap dropdown-top position-relative">
                    <a href="https://street.co.uk/login" target="_blank">
                      <i className="icon-signup"></i> Sign in
                    </a>
                    <div class="sub-menu-top">
                      <div class="sub-menu">
                        <a href="https://street.co.uk/login" target="_blank">Login to Redbrik</a>
                      </div>
                        </div>

                  </div>*/}
                  <div className="login-wrap dropdown-top position-relative">
                    { isMobileOnly && isClient ?
                      <>
                        <a href="javascript:void(0);" className="login-wrap">
                          <i className="icon-call"></i>
                          <i className="icon-call-selected"></i>
                        </a>
                        <div class="sub-menu-top contact">
                          <div class="sub-menu">
                            {
                              parse(site_contact)
                            }
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <Link className="login-wrap" to="/contact/">
                          <i className="icon-call"></i>
                          <i className="icon-call-selected"></i>
                        </Link>
                        <div class="sub-menu-top contact">
                          <div class="sub-menu">
                            {
                              parse(site_contact)
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="val-btn">
                  <Link className={`btn btn-white`} to={'/property-valuation'}>Book a Valuation</Link>
                  </div>                 
               
                  <button
                    onClick={closeHam}
                    className={ham ? "hamburger cross" : "hamburger"}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <i className="icon-cross"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={`${hamSearch ? "menu-list-wrapper" : "menu-list-wrapper d-none"} wrapper-find-property`}>
          <div className="dropdown-top position-relative find-a-property">
            <button class="hamburger cross" onClick={closeHamSearch}><span></span><span></span><span></span><i className="icon-cross"></i></button>
            <div className="sub-menu-top">
              <div className="sub-menu find-a-property-popup">
                <div className="title">Find your perfect home</div>
                <SearchForm page={page} />
              </div>
            </div>
          </div>
        </div>

        <div className={ham ? "menu-list-wrapper" : "menu-list-wrapper d-none"}>
          <nav className="menu-bar">
            <ul>
              {state &&
                state.map((item, i) => (
                  <li onClick={() => handlerClick(item, i)}>
                      {item.Parent_Link ?
                        <GenerateLink link={item.Parent_Link}>{item.Parent_Label}</GenerateLink> : <GenerateLink link={item.Parent_Link}>{item.Parent_Label}</GenerateLink>}
                      {" "}
                      {item.Sub_Menus.length > 0 && (
                        <i
                          className={item.isopen ? "icon-minus-burger" : "icon-plus-burger"}
                        ></i>
                      )}
                    <div className={item.isopen ? "inside-list open-list" : "inside-list"}>
                      <ul className="inside-listing">
                        {item.Sub_Menus &&
                          item.Sub_Menus.map((newitem) => (
                            <li>
                              <GenerateLink link={newitem.Link}>{newitem.Label}</GenerateLink>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                ))}
                {/*<li><a href="https://street.co.uk/login" target="_blank">Sign in</a></li>*/}
            </ul>


            {/* <ul className="mobile-nav-two">
              {statetop &&
                statetop.map((item, i) => (
                  <li onClick={() => handlerClickTop(item, i)}>
                    <Link>
                      {item.Link ?
                        <GenerateLink link={item.Link}>{item.Label}</GenerateLink> : <>{item.Label}</>}
                      {" "}
                      {item.Add_Sub_Menu.length > 0 && (
                        <i
                          className={item.isopen ? "icon-minus" : "icon-plus"}
                        ></i>
                      )}
                    </Link>
                    <div className={item.isopen ? "" : "inside-list"}>
                      <ul className="inside-listing">
                        {item.Add_Sub_Menu &&
                          item.Add_Sub_Menu.map((newitem) => (
                            <li>
                              <GenerateLink link={newitem.Submenu_Link}>{newitem.Submenu_Label}</GenerateLink>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                ))}
            </ul> */}
          </nav>
          {/*
        <ul className="privacy-link">
          {quick_links &&
            quick_links.map((item, i) => (
              <li>
                <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
              </li>
            )
          )}
        </ul>*/}
          <div className="privacy-link valuation-overlay">
            { /* <div className="h3">How much is my property worth?</div>
            <p>Get the full, bespoke sales or letting valuation your property deserves from our Sheffield or Chesterfield team.</p> */}
            <Link className={`btn`} to={'/property-valuation'}>Book a Valuation</Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;



